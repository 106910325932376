import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Custom Outreach Writing Services",
  whatWeOffer: {
    leftPart: "Building an outreach strategy is a task best left to professionals. Not all links are quality links. If a shady site features a link leading to your website, search engines will see this as a negative factor.",
    rightPart: "Here at Podroom Creative, we focus on providing our clients with relevant outreach services. Our outreach specialist will specifically target sites that can easily be connected to your own."
  },
  processText: "Get in touch with us today, and we'll discuss your budget, expectations, and come up with a custom outreach strategy specifically tailored to you.",
  testimonials: testimonials
}

const meta = {
  title: "Custom Outreach Writing Services",
  description: "Building an outreach strategy is a task best left to professionals. Not all links are quality links. If a shady site features a link leading to your website, search engines will see this as a negative factor."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/custom-outreach";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)